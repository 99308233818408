// colors
// ----------------------------
$white: #fff;

$black-120: #000;
$black-100: #333;
$black-90: #4c4c4c;
$black-80: #646464;
$black-70: #7c7c7c;
$black-60: #949494;
$black-50: #acacac;
$black-40: #c4c4c4;
$black-30: #dcdcdc;
$black-20: #f4f4f4;

$blue-100: #003468;
$blue-90: #1e4c7a;
$blue-80: #3d648c;
$blue-70: #5b7c9e;
$blue-60: #7995b0;
$blue-50: #97adc1;
$blue-40: #b6c5d3;
$blue-30: #d4dde5;
$blue-20: #f2f5f7;

$green-light: #e6f5cf;

$orange-130: #803700;
$orange-120: #994200;
$orange-110: #cb5f00;
$orange-100: #ff7500;
$orange-90: #ff9133;
$orange-80: #ff9a4c;
$orange-70: #ffac66;
$orange-60: #ffba80;
$orange-50: #ffc899;
$orange-40: #ffd5b0;
$orange-30: #ffe3cc;
$orange-20: #fff1e5;

$yellow-90: #c4c100;
$yellow-50: #fefde5; 
$yellow: #F5F201;

$google-blue: #1A72E8;
$google-blue-90: #145bb9;
$google-blue-50: #bad4f8;

$color-link: $google-blue;
$color-link-hover: $google-blue-90;
$color-link-active: $google-blue-90;

//use $color-error instead of $color-error-text
$color-error: #d91a2a;
$color-error-hover: #99171d;
$color-error-message: #ffdddc;
$color-error-text: #d51500;
$color-success: #217306;
$color-success-hover: #235413;
$color-success-message: #e6f5cf;
$color-info: $orange-120;
$color-info-hover: $orange-130;
$color-info-message: #fff1c0;
$color-tip: #1e4c7a;
$color-tip-hover: $blue-100;
$color-tip-message: #e4eeff;

$color-highlight-info: $color-link;

$body-gold: linear-gradient(#f7e994, #d9a600);
$body-silver: linear-gradient($black-30, $black-50);
$body-bronze: linear-gradient(#f7c694, #bf7326);
$body-black: $black-120;
$body-gray: $black-60;
$body-white: $white;
$body-brown: $orange-120;
$body-beige: #dbaf6e;
$body-yellow: #f7c81e;
$body-orange: $orange-100;
$body-red: $color-error;
$body-violet: #991289;
$body-blue: #0059b2;
$body-green: #38a614;
$body-dark-sky-blue: #33AADD;

$body-other: url('data:image/svg+xml;base64,PHN2ZyBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCI+PHN0eWxlPjwhW0NEQVRBWw0KCS5zdDB7ZmlsbDpub25lO3N0cm9rZTojQzZDNkM2O3N0cm9rZS1taXRlcmxpbWl0OjEwO30NCl1dPjwvc3R5bGU+PHBhdGggZD0iTTAgMGgzMHYzMEgweiIvPjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tNDAgNDBsNTAtNTBNLTMwIDQwbDUwLTUwTS0yMCA0MGw1MC01ME0tMTAgNDBsNTAtNTBNMCA0MGw1MC01ME0xMCA0MGw1MC01ME0yMCA0MGw1MC01MCIvPjwvc3ZnPg==');

// spacings
// ----------------------------
$XS: 4px;
$S: 8px;
$M: 12px;
$L: 16px;
$XL: 20px;
$XXL: 28px;
$XXXL: 40px;
$XXXXL: 60px;

// viewports
// ----------------------------
$viewportXS: 0; // 0 - 320
$viewportS: 321px; // 321 - 767
$viewportM: 768px; // 768 - 1023
$viewportL: 1024px; // 1024 - 1099
$viewportXL: 1100px; // 1100 - 1269
$viewportXXL: 1270px; // 1270 - ∞

$viewportFireplace: 1440px;

//old viewport variables for compatibility, please use $viewportX instead
$mobile: $viewportS;
$tablet: $viewportM;
$desktop: $viewportL;

// sizes
// ----------------------------
$form-border-radius: 4px;

// animations
// ----------------------------
$transition-time: 0.2s;

// susy
// ----------------------------
$susy-grid-background: hide !default; // can be "show" as well
$gutter-width: 16px;
$grid-column-width: 77px;
$grid-max-width: $gutter-width * 11 + $grid-column-width * 12;
$side-ad-width: 160px;
$gap-between-ad-and-grid: 10px;

$susy: (
  columns: 12,
  global-box-sizing: border-box,
  gutters: $gutter-width / $grid-column-width,
  gutter-position: split,
  container: $grid-max-width,
  debug: (
    image: $susy-grid-background,
  )
);
