@import '../01-settings/variables';
@import '../02-sass-tools/sass-mq';
@import '../02-sass-tools/utils';

.sc-highlight-color {
  color: $color-link;
}

.sc-clearfix {
  @include clearfix;
}

.sc-pull-right {
  float: right !important;
}

.sc-pull-left {
  float: left !important;
}

.sc-text-left {
  text-align: left !important;
}

.sc-text-right {
  text-align: right !important;
}

.sc-text-center {
  text-align: center !important;
}

.sc-text-justify {
  text-align: justify !important;
}

.sc-text-nowrap {
  white-space: nowrap !important;
}

.sc-block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sc-absolute-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.sc-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sc-block {
  display: block !important;
}

.sc-hidden {
  display: none !important;
}

.sc-visible {
  display: initial !important;
}

.sc-overflow-hidden {
  overflow: hidden !important;
}

//s viewport
.sc-hidden-at-s {
  @include mqminmax(0, $viewportM - 1) {
    display: none !important;
  }
}

.sc-visible-at-s {
  @include mqmin($viewportM) {
    display: none;
  }
}

//m viewport
.sc-hidden-at-m {
  @include mqminmax($viewportM, $viewportL - 1) {
    display: none !important;
  }
}

.sc-visible-at-m {
  @include mqminmax($viewportS - 1, $viewportM - 1) {
    display: none;
  }

  @include mqmin($viewportL) {
    display: none;
  }
}

//l viewport
.sc-hidden-at-l {
  @include mqminmax($viewportL, $viewportXL - 1) {
    display: none !important;
  }
}

.sc-visible-at-l {
  @include mqminmax($viewportS - 1, $viewportL - 1) {
    display: none;
  }

  @include mqmin($viewportXL) {
    display: none;
  }
}

//xl viewport
.sc-hidden-at-xl {
  @include mqmin($viewportXL) {
    display: none !important;
  }
}

.sc-visible-at-xl {
  @include mqmax($viewportXL - 1) {
    display: none;
  }
}

.sc-box-spacing {
  margin-bottom: 30px;

  @include mqmin($viewportM) {
    margin-bottom: 40px;
  }
}

//TODO: move to layout
.sc-section {
  border-bottom: 1px solid $black-30;
  background-color: $white;
  padding: 0 $XL;

  &.sc-section-transparent {
    background-color: transparent;
  }
}

.sc-icon-s {
  width: $M;
  height: $M;
}

.sc-icon-m {
  width: $L;
  height: $L;
}

.sc-icon-l {
  width: $XL;
  height: $XL;
}

.sc-icon-xl {
  width: 24px;
  height: 24px;
}

.sc-icon-xxl {
  width: $XXXL;
  height: $XXXL;
}

/***** 
******
  
  Spacing classes

******
*****/

.sc-padding-bottom-xs {
  padding-bottom: $XS;
}

.sc-padding-bottom-s {
  padding-bottom: $S;
}

.sc-padding-bottom-m {
  padding-bottom: $M;
}

.sc-padding-bottom-l {
  padding-bottom: $L;
}

.sc-padding-bottom-xl {
  padding-bottom: $XL;
}

.sc-padding-bottom-xxl {
  padding-bottom: $XXL;
}

.sc-padding-bottom-xxxl {
  padding-bottom: $XXXL;
}

.sc-padding-bottom-xxxxl {
  padding-bottom: $XXXXL;
}

.sc-padding-bottom-xxxl-for-s {
  @include mqmin($viewportS) {
    padding-bottom: $XXXL;
  }
}

.sc-padding-bottom-xxxxl-for-m {
  @include mqmin($viewportM) {
    padding-bottom: $XXXXL;
  }
}

/**/

.sc-margin-top-xs {
  margin-top: $XS;
}

.sc-margin-top-s {
  margin-top: $S;
}

.sc-margin-top-m {
  margin-top: $M;
}

.sc-margin-top-l {
  margin-top: $L;
}

.sc-margin-top-xl {
  margin-top: $XL;
}

.sc-margin-top-xxl {
  margin-top: $XXL;
}

.sc-margin-top-xxxl {
  margin-top: $XXXL;
}

.sc-margin-top-xxxxl {
  margin-top: $XXXXL;
}

.sc-margin-top-xxxl-for-s {
  @include mqmin($viewportS) {
    margin-top: $XXXL;
  }
}

.sc-margin-top-xxxxl-for-m {
  @include mqmin($viewportM) {
    margin-top: $XXXXL;
  }
}

.sc-margin-right-xs {
  margin-right: $XS;
}

.sc-margin-right-s {
  margin-right: $S;
}

.sc-margin-right-m {
  margin-right: $M;
}

.sc-margin-right-l {
  margin-right: $L;
}

.sc-padding-horizontal-l {
  padding-left: $L;
  padding-right: $L;
}

.sc-padding-vertical-xxl {
  padding-top: $XXL;
  padding-bottom: $XXL;
}

.sc-padding-vertical-xxxl {
  padding-top: $XXXL;
  padding-bottom: $XXXL;
}

.sc-padding-vertical-m-l {
  padding-top: $M;
  padding-bottom: $M;

  @include mqmin($viewportM) {
    padding-top: $L;
    padding-bottom: $L;
  }
}

.sc-padding-vertical-l-xl {
  padding-top: $L;
  padding-bottom: $L;

  @include mqmin($viewportM) {
    padding-top: $XL;
    padding-bottom: $XL;
  }
}
/***** 
*****/

// LEAGACY CODE - SHOULD NOT BE USED
//xl viewport
@include mqmin($viewportXL) {
  .sc-hidden-xl {
    display: none;
  }
  .sc-visible-xl {
    display: initial;
  }
}

//l viewport
@include mqmax($viewportXL - 1) {
  .sc-hidden-l {
    display: none;
  }
  .sc-visible-l {
    display: initial;
  }
}

//m viewport
@include mqmax($viewportL - 1) {
  .sc-hidden-m {
    display: none;
  }
  .sc-visible-m {
    display: initial;
  }
}

//s viewport
@include mqmax($viewportM - 1) {
  .sc-hidden-s {
    display: none;
  }
  .sc-visible-s {
    display: initial;
  }
}

.sc-telekom-bar {
  padding-right: $XL;
  text-align: right;
  as24-icon[type='t-online'] {
    height: $XL - 1;
  }
}

.sc-focus-visible-only {
  button:focus {
    outline: 5px auto;
    outline: 5px auto -webkit-focus-ring-color;
  }
  a:focus {
    outline: 5px auto;
    outline: 5px auto -webkit-focus-ring-color;
  }
  button a {
    margin: 5px;
  }
}
//LEAGACY CODE - SHOULD NOT BE USED
