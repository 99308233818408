@import '../../../01-settings/variables';
@import './navigation-v2-mixin.scss';

$navigation-breakpoint: 1000px;

.sc-navigation-v2 {
  @include sc-navigation-v2-mixin($navigation-breakpoint);
}

.skip-main {
  position: absolute;
  width: 0;
  overflow: hidden;

  &:focus,
  &:active {
    color: $white;
    display: block;
    background-color: $black-100;
    width: 30%;
    overflow: auto;
    margin: 10px 35%;
    padding: 5px;
    border-radius: 5px;
    border: 2px solid $black-60;
    text-align: center;
    font-size: 1.2em;
    z-index: 140; // more than header
  }
}
