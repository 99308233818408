@import '../node_modules/susy/sass/susy';
@import '../01-settings/variables';
@import '../02-sass-tools/sass-mq';
@import '../02-sass-tools/utils';

.sc-content-container {
  @include container;
  position: relative;
  overflow-x: hidden;
  box-sizing: content-box;
}

.sc-grid-row {
  @include clearfix;
  $negativMargin: percentage(map-get($susy, gutters) / susy-sum(map-get($susy, columns), map-get($susy, gutters), wide)) / 2;
  margin-left: -$negativMargin;
  margin-right: -$negativMargin;

  > *:empty {
    min-height: 1px;
  }

  @for $i from 1 through 12 {
    .sc-grid-col-#{$i} {
      @include span($i);
    }
  }

  //xl viewport
  @include mqmin($viewportXL) {
    @for $i from 1 through 12 {
      .sc-grid-col-xl-#{$i} {
        @include span($i);
      }
    }
  }

  //l viewport
  @include mqmax($viewportXL - 1) {
    @for $i from 1 through 12 {
      .sc-grid-col-l-#{$i} {
        @include span($i);
      }
    }
  }

  //m viewport
  @include mqmax($viewportL - 1) {
    @for $i from 1 through 12 {
      .sc-grid-col-m-#{$i} {
        @include span($i);
      }
    }
  }

  //s viewport
  @include mqmax($viewportM - 1) {
    @for $i from 1 through 12 {
      .sc-grid-col-s-#{$i} {
        @include span($i);
      }
    }
  }

  //xs viewport
  @include mqmax($viewportS - 1) {
    @for $i from 1 through 12 {
      .sc-grid-col-xs-#{$i} {
        @include span($i);
      }
    }
  }
}
