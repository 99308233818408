@import '../../../01-settings/variables';

.sc-highlight {
  display: inline-block;
  background-color: $black-100;
  color: $white;
  border-radius: 4px;
  vertical-align: middle;
  padding: $XS $S;
  margin: 2px 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.sc-highlight--silent {
  display: inline-block;
  background-color: $black-20;
  color: $black-100;
  border-radius: 4px;
  vertical-align: middle;
  padding: $XS $S;
  margin: 2px 1px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
