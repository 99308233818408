@import '../01-settings/variables';

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

// HTML5 display-role reset for older browsers
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-smooth: always;
}

*::selection {
  background-color: $yellow;
}

html {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-text-size-adjust: 100%; // Prevent font scaling in landscape while allowing user zoom
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

body, [data-showcar-ui] {
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: $black-100;
  -webkit-overflow-scrolling: touch;
  background-color: $black-20;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

hr {
  margin-top: $M;
  margin-bottom: $M;
  border: 0;
  border-top: 1px solid $black-30;
}

// Fix for Safari rendering issue, see: http://stackoverflow.com/questions/33335886/on-iphone-safari-when-typing-in-an-input-the-screen-goes-white
input,
textarea,
select {
  position: relative;
}

// Remove extra whitespace produced by picture element
picture {
  img {
    vertical-align: top;
  }
}
