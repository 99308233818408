@import '../../../01-settings/variables';
@import '../../../02-sass-tools/fonts';

// variables
// -----------------------------
$sc-btn-bob-text-color: $black-100;
$sc-btn-ross-text-color: $black-100;
$sc-btn-ghost-text-color: $black-100;
$sc-btn-marketing-text-color: $black-100;

$sc-btn-bob-background-color: $yellow;
$sc-btn-ross-background-color: $black-100;
$sc-btn-ghost-background-color: $white;
$sc-btn-marketing-background-color: $white;

$sc-btn-bob-hover-background-color: #fffb19;
$sc-btn-ross-hover-background-color: $black-120;
$sc-btn-ghost-hover-text-color: $black-120;
$sc-btn-marketing-hover-text-color: $black-120;

$sc-btn-bob-active-background-color: $yellow-90;
$sc-btn-ross-active-background-color: $black-120;
$sc-btn-ghost-active-text-color: $black-120;
$sc-btn-marketing-active-text-color: $black-120;

$sc-btn-bob-disabled-background-color: $yellow-50;
$sc-btn-bob-disabled-font-color: $black-50;
$sc-btn-ross-disabled-background-color: $black-50;
$sc-btn-ghost-disabled-text-color: $black-50;
$sc-btn-marketing-disabled-text-color: $black-50;

@mixin button {
  // max-width: 288px;
  user-select: none;
  @include font(M, $bold: true, $silent: false, $line-height: 1.3);

  display: inline-block;
  padding: 10px 16px;

  appearance: none;
  border: none;
  border-radius: $form-border-radius;
  color: $black-100;
  transition: all $transition-time ease-in;
  transition-property: color, background-color, border-color;
  text-decoration: none;
  text-align: center;

  &:hover {
    color: $black-100;
  }

  [class^='icon-'] {
    width: $L;
    height: $L;
    display: inline-block;
    background-size: contain;
    position: relative;
  }
}

.sc-btn-bob {
  @include button;

  background-color: $sc-btn-bob-background-color;
  color: $sc-btn-bob-text-color;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  // border: solid $sc-btn-ross-background-color 2px;

  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    background-color: $sc-btn-bob-hover-background-color;
  }

  &:active {
    background-color: $sc-btn-bob-active-background-color;
  }

  &[disabled] {
    background-color: $sc-btn-bob-disabled-background-color;
    color: $sc-btn-bob-disabled-font-color;
  }
}

.sc-btn-ross {
  @include button;

  background-color: $sc-btn-ghost-background-color;
  border: solid $sc-btn-ross-background-color 2px;
  color: $sc-btn-ross-background-color;

  padding-top: 8px;
  padding-bottom: 8px;

  &:hover {
    border: solid $sc-btn-ross-hover-background-color 2px;
    color: $sc-btn-ross-hover-background-color;
  }

  &:active {
    border: solid $sc-btn-ross-active-background-color 2px;
    color: $sc-btn-ross-active-background-color;
  }

  &[disabled] {
    border: solid $sc-btn-ross-disabled-background-color 2px;
    color: $sc-btn-ross-disabled-background-color;
  }
}

.sc-btn-bob-ghost {
  @include button;

  background-color: $sc-btn-ghost-background-color;
  border: solid $sc-btn-ghost-text-color 2px;
  color: $sc-btn-ghost-text-color;

  padding-top: 8px;
  padding-bottom: 8px;

  &:hover {
    border: solid $sc-btn-ghost-hover-text-color 2px;
    color: $sc-btn-ghost-hover-text-color;
  }

  &:active {
    border: solid $sc-btn-ghost-active-text-color 2px;
    color: $sc-btn-ghost-active-text-color;
  }

  &[disabled] {
    border: solid $sc-btn-ghost-disabled-text-color 2px;
    color: $sc-btn-ghost-disabled-text-color;
  }
}

.sc-btn-ross-ghost {
  @include button;

  background-color: $sc-btn-ghost-background-color;
  border: solid $sc-btn-ross-background-color 2px;
  color: $sc-btn-ross-background-color;

  padding-top: 8px;
  padding-bottom: 8px;

  &:hover {
    border: solid $sc-btn-ross-hover-background-color 2px;
    color: $sc-btn-ross-hover-background-color;
  }

  &:active {
    border: solid $sc-btn-ross-active-background-color 2px;
    color: $sc-btn-ross-active-background-color;
  }

  &[disabled] {
    border: solid $sc-btn-ross-disabled-background-color 2px;
    color: $sc-btn-ross-disabled-background-color;
  }
}

.sc-btn-marketing {
  @include button;

  background-color: $sc-btn-marketing-background-color;
  border: solid $white 1px;
  color: $sc-btn-marketing-text-color;

  padding-top: 9px;
  padding-bottom: 9px;

  &:hover {
    color: $sc-btn-marketing-hover-text-color;
  }

  &:active {
    color: $sc-btn-marketing-active-text-color;
  }

  &[disabled] {
    color: $sc-btn-marketing-disabled-text-color;
  }
}

.sc-btn-block {
  display: block;
  width: 100%;
}

/**
 * DEPRECATED: only for compatibility
 * TODO: remove asap
 */
.sc-btn-bob-l,
.sc-btn-bob-m {
  @include button;
  box-shadow: 1px 1px 5px #949494;

  background-color: $orange-100;

  &:hover {
    background-color: $orange-110;
  }

  &:active {
    background-color: $orange-120;
  }

  &[disabled] {
    background-color: $orange-50;
  }
}

.sc-btn-ross-l,
.sc-btn-ross-m {
  @include button;

  background-color: $sc-btn-ghost-background-color;
  border: solid $sc-btn-ross-background-color 2px;
  color: $sc-btn-ross-background-color;

  padding-top: 9px;
  padding-bottom: 9px;

  &:hover {
    border: solid $sc-btn-ross-hover-background-color 2px;
    color: $sc-btn-ross-hover-background-color;
  }

  &:active {
    border: solid $sc-btn-ross-active-background-color 2px;
    color: $sc-btn-ross-active-background-color;
  }

  &[disabled] {
    border: solid $sc-btn-ross-disabled-background-color 2px;
    color: $sc-btn-ross-disabled-background-color;
  }
}

.sc-btn-ghost {
  @include button;

  background-color: $sc-btn-ghost-background-color;
  border: solid $sc-btn-ghost-text-color 2px;
  color: $sc-btn-ghost-text-color;

  padding-top: 8px;
  padding-bottom: 8px;

  &:hover {
    border: solid $sc-btn-ghost-hover-text-color 2px;
    color: $sc-btn-ghost-hover-text-color;
  }

  &:active {
    border: solid $sc-btn-ghost-active-text-color 2px;
    color: $sc-btn-ghost-active-text-color;
  }

  &[disabled] {
    border: solid $sc-btn-ghost-disabled-text-color 2px;
    color: $sc-btn-ghost-disabled-text-color;
  }
}
