@import '03-generic/reset';
@import '03-generic/fonts';

@import '04-elements/elements';

@import '05-layout/grid';
@import '05-layout/ad';
@import '05-layout/sticky/sticky';

@import '06-components/atoms/list/list';
@import '06-components/atoms/button/button';
@import '06-components/atoms/collapse/collapse';
@import '06-components/atoms/color-selector/color-selector';
@import '06-components/atoms/custom-dropdown/custom-dropdown';
@import '06-components/atoms/custom-dropdown-icon/custom-dropdown-icon';
@import '06-components/atoms/highlight/highlight';
@import '06-components/atoms/disruptor/disruptor';
@import '06-components/atoms/google-map/google-map';
@import '06-components/atoms/inlined-info/inlined-info';
@import '06-components/atoms/label/label';
@import '06-components/atoms/input/input';
@import '06-components/atoms/lazy-image/lazy-image';
@import '06-components/atoms/lazy-shimmer/lazy-shimmer';
@import '06-components/atoms/lightbox/lightbox';
@import '06-components/atoms/link/link';
@import '06-components/atoms/popover/popover';
@import '06-components/atoms/rotating-arrow/rotating-arrow';
@import '06-components/atoms/spinner/spinner';
@import '06-components/atoms/stepper/stepper';
@import '06-components/atoms/table/table';
@import '06-components/atoms/tabs/tabs-icons';
@import '06-components/atoms/tabs/tabs-text';
@import '06-components/atoms/tag/tag';
@import '06-components/atoms/tooltip/tooltip';

@import '06-components/molecules/breadcrumb/breadcrumb';
@import '06-components/molecules/page-header/page-header';
@import '06-components/molecules/button-with-icon/button-with-icon';
@import '06-components/molecules/expandable-box/expandable-box';
@import '06-components/molecules/input-group/input-group';
@import '06-components/molecules/link-with-icon/link-with-icon';
@import '06-components/molecules/list-with-links/list-with-links';
@import '06-components/molecules/notification/notification';
@import '06-components/molecules/small-footer/small-footer';
@import '06-components/molecules/small-header/small-header';
@import '06-components/molecules/media-section/media-section';
@import '06-components/molecules/content-teaser/content-teaser';
@import '06-components/molecules/link-text-box/link-text-box';
@import '06-components/molecules/separated-link-list/separated-link-list';
@import '06-components/molecules/unique-link-list/unique-link-list';
@import '06-components/molecules/ordered-link-list/ordered-link-list';
@import '06-components/molecules/menu-tabs/menu-tabs';

@import '06-components/organisms/benefit-group/benefit-group';
@import '06-components/organisms/navigation-v2/navigation-v2';
@import '06-components/organisms/pagination/pagination';
@import '06-components/organisms/search-mask/search-mask';
@import '06-components/organisms/spy-navigation/spy-navigation';
@import '06-components/organisms/card-group/card-group';
@import '06-components/organisms/teaser-external/teaser-external';
@import '06-components/organisms/superhero/superhero';

@import '07-utilities/utils';

@font-face {
  font-family: MakeItSans;
  src: url(./MakeItSans-Regular.otf);
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: MakeItSans;
  src: url(./MakeItSans-Bold.otf);
  font-weight: 600;
  font-display: swap;
}

body {
  font-family: MakeItSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}
