@import '../../../01-settings/variables';

//TODO remove Multiple Breadcrumbs, mobile is better
@mixin arrow($direction) {
  content: '';
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st1%7Bfill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st2%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23333333;%7D .st3%7Bfill:%23333333;%7D .st4%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23333333;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st5%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st6%7Bfill:none;stroke:%23C6C6C6;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st7%7Bfill:none;stroke:%23333333;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;%7D .st8%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%2395C11F;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D .st9%7Bfill-rule:evenodd;clip-rule:evenodd;fill:none;stroke:%23E30613;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;%7D%0A%3C/style%3E%3Cpath class='st3' d='M12,18c-0.3,0-0.5-0.1-0.7-0.3l-8-8c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l7.3,7.3l7.3-7.3c0.4-0.4,1-0.4,1.4,0 s0.4,1,0,1.4l-8,8C12.5,17.9,12.3,18,12,18z'/%3E%3C/svg%3E"); /* stylelint-disable-line string-quotes */
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  margin-bottom: -2px;

  @if $direction == 'left' {
    transform: rotate(90deg);
    margin-right: $S;
  }

  @if $direction == 'right' {
    transform: rotate(-90deg);
    margin-left: $S;
    margin-right: 2px;
  }
}

.sc-breadcrumbs {
  padding-left: $L;
  margin-top: 10px; //give all breadcrumbs a common top margin within all pages

  li {
    display: none;
    margin-top: 10px;

    * {
      display: inline-block;
    }

    &:last-child {
      display: inline-block;
    }

    &:last-child::before {
      @include arrow('left');
    }

    @include mqmin(500px) { // 500px?
      display: inline-block;

      &::after {
        @include arrow('right');
      }

      &:not(:only-child):last-child::before,
      &:last-child::after {
        content: none;
        float: left;
      }
    }
  }

  // Block modifier
  &--viewport-s {
    display: inline-block;

    // Mark the element that should be visible on viewportS
    li.sc-breadcrumb-item--viewport-s {
      display: inline-block;
    }

    @include mqmax(500px) { // 500px?
      li {
        display: none;

        &::before {
          @include arrow('left');
        }

        &::after {
          display: none;
        }

        &:last-child {
          display: none;
        }
      }
    }
  }
}
